.anchor-hover {
  position: relative;
  z-index: 9;
  color: #102C57;
  padding: 0;
  overflow: hidden;
  transition: transform .5s;
  display: block;
}
.anchor-hover::after {
  content: attr(data-after);
  display: inline-block;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  transition: inherit;
}
.anchor-hover > span {
  display: inline-block;
  transform: translateY(0%);
  transition: inherit;
}
.anchor-hover:hover > span {
  transform: translateY(-100%);
}
.anchor-hover:hover::after {
  transform: translateY(0%);
}

.contact-hover {
  position: relative;
  z-index: 9;
  color: #1A2130;
  padding: 0;
  overflow: hidden;
  transition: transform .5s;
  display: block;
}
.contact-hover::after {
  content: attr(data-after);
  display: inline-block;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  transition: inherit;
}
.contact-hover > span {
  display: inline-block;
  transform: translateY(0%);
  transition: inherit;
}
.contact-hover:hover > span {
  transform: translateY(-100%);
}
.contact-hover:hover::after {
  transform: translateY(0%);
}

nav {
  width: 100%;
  height: 6em;
}

.burger-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.burger-bar {
  /* width: 2em;
  height: 0.3em;
  border-radius: 0.5em; */
}

.menu {
  width: 100%;
  height: 100vh;
  background-color: rgb(154, 181,240);
  position: absolute;
  top: 0;
  z-index: -1;
}

/* ------------- sliding menu ------------------ */
.hidden {
  display: none;
}

.visible {
  display: inherit;
}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1){
  transform: rotate(45deg) translate(0.5em, 0.5em);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2){
  transform: scale(0.1);
  transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3){
  transform: rotate(135deg) translate(-0.5em, 0.5em);
  transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

.navbar-hover:before, .navbar-hover:after {
	display: inline-block;
	opacity: 0;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
	-moz-transition: -moz-transform 0.3s, opacity 0.2s;
	transition: transform 0.3s, opacity 0.2s;
}

.navbar-hover:before {
	margin-right: 10px;
	content: '[';
	-webkit-transform: translateX(20px);
	-moz-transform: translateX(20px);
	transform: translateX(20px);
}

.navbar-hover:after {
	margin-left: 10px;
	content: ']';
	-webkit-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	transform: translateX(-20px);
}

.navbar-hover:hover:before, .navbar-hover:hover:after, .navbar-hover:focus:before, .navbar-hover:focus:after {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px);
}

.anchor-hover {
  position: relative;
  z-index: 9;
  color: #102C57;
  padding: 0;
  overflow: hidden;
  transition: transform .5s;
  display: block;
}
.anchor-hover::after {
  content: attr(data-after);
  display: inline-block;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  transition: inherit;
}
.anchor-hover > span {
  display: inline-block;
  transform: translateY(0%);
  transition: inherit;
}
.anchor-hover:hover > span {
  transform: translateY(-100%);
}
.anchor-hover:hover::after {
  transform: translateY(0%);
}

.email-navbar-hover {
  position: relative;
  z-index: 9;
  padding: 0;
  overflow: hidden;
  transition: transform .5s;
  display: block;
}
.email-navbar-hover::after {
  content: attr(data-after);
  display: inline-block;
  transform: translateY(100%);
  position: absolute;
  left: 0;
  transition: inherit;
}
.email-navbar-hover > span {
  display: inline-block;
  transform: translateY(0%);
  transition: inherit;
}
.email-navbar-hover:hover > span {
  transform: translateY(-100%);
}
.email-navbar-hover:hover::after {
  transform: translateY(0%);
}

.introduction {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  background-color: #1A2130;
}

.introduction svg {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% + 300px);
}

.introduction svg path {
  fill: #1A2130;
}

.introduction p {
  display: flex;
  color: #F5EFE6;
  font-size: 42px;
  align-items: center;
  z-index: 1;
}

.description{
  .body{
      p{
          margin: 0px;
          &:nth-of-type(1){
              /* font-size: 36px; */
              gap: 8px;
              line-height: 1.3;
              span{
                  margin-right: 3px;
              }
              .mask{
                  position: relative;
                  overflow: hidden;
                  display: inline-flex;
              }
          }
          &:nth-of-type(2){
              font-size: 18px;
              width: 80%;
              font-weight: 300;
          }
      }
      .button{
          top: 80%;
          left: calc(100% - 200px); 
          width: 180px;
          height: 180px;
          background-color: #1C1D20;
          color: white;
          border-radius: 50%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          p{
              margin: 0px;
              font-size: 16px;
              font-weight: 300;
              position: relative;
              z-index: 1;
          }
      }
  }
  .moreWork{
      padding: 20px;
      border-radius: 40px;
      border: 1px solid grey;
  }
}

/* styles.css */
.carousel {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
}

.carousel.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

@media (max-width: 768px) {
    .carousel {
        flex-direction: column;
    }
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #F5EFE6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
